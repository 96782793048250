import dayjs from '@troon/dayjs';

export function dayTimeToDate(dayTime: {
	day: { year: number; month: number; day: number };
	time: { hour: number; minute: number };
}) {
	const { day, time } = dayTime;
	return new Date(day.year, day.month - 1, day.day, time.hour, time.minute);
}

export function dayToDayJs(date: { year: number; month: number; day: number }, timezone: string) {
	const { year, month, day } = date;
	return dayjs.tz(`${year}-${month}-${day}`, timezone).startOf('day');
}

export const formatDateTime = (date: Date) => dayjs(date).format('dddd, MMMM D [at] h:mm A');

export function dateToDayTime(date: Date) {
	return {
		day: {
			year: date.getFullYear(),
			month: date.getMonth() + 1,
			day: date.getDate(),
		},
		time: {
			hour: date.getHours(),
			minute: date.getMinutes(),
		},
	};
}

// Acceptable for <time datetime="…">
export const formatDateTimeAttribute = (date: Date, includeHours: boolean = true) => {
	return dayjs(date).format(`YYYY-MM-DD${includeHours ? '[T]HH:mm' : ''}`);
};

const day = dayjs().startOf('day');
export const shortTimeframes: Record<string, [number, number]> = {
	'All day': [0, 24],
	'Early morning': [0, 10],
	'Late morning': [10, 12],
	'All morning': [0, 12],
	'Early afternoon': [12, 15],
	'Late afternoon': [15, 17],
	'All afternoon': [12, 17],
	Twilight: [17, 24],
};

export const timeframes: Record<string, [number, number]> = {
	'All day': [0, 24],
	[`Early morning • before ${day.set('hour', 10).format('h A')}`]: [0, 10],
	[`Late morning • ${day.set('hour', 10).format('h A')}–${day.set('hour', 12).format('h A')}`]: [10, 12],
	[`All morning • before ${day.set('hour', 12).format('h A')}`]: [0, 12],
	[`Early afternoon • ${day.set('hour', 12).format('h A')}–${day.set('hour', 15).format('h A')}`]: [12, 15],
	[`Late afternoon • ${day.set('hour', 15).format('h A')}–${day.set('hour', 17).format('h A')}`]: [15, 17],
	[`All afternoon • ${day.set('hour', 12).format('h A')}–${day.set('hour', 17).format('h A')}`]: [12, 17],
	[`Twilight • after ${day.set('hour', 17).format('h A')}`]: [17, 24],
};

export function timeFrameNameFromHours(start: number, end: number, short: boolean = false) {
	const found = Object.entries(short ? shortTimeframes : timeframes).find(([, [tStart, tEnd]]) => {
		return tStart === start && tEnd === end;
	});

	return found ? found[0] : undefined;
}
